import { observer } from 'mobx-react'
import Header from '../../components/Header/Header'
import PortfolioPicker from '../../components/PortfolioPicker/PortfolioPicker'
import Orders from '../../components/Orders/Orders'
import Settings from '../../components/Settings/Settings'
import AlgorithmMenu from '../../components/AlgorithmMenu/AlgorithmMenu'
import Executor from '../../components/Executor/Executor'
import { useStores } from '../../use-stores'
import React, { useEffect } from 'react'
import PopupMenu from '../../components/PopupMenu/PopupMenu'
import ActiveOrders from '../../components/ActiveOrders/ActiveOrders'
import AreaTree from '../../components/AreaTree/AreaTree'
import { SignalModal } from '../../components/SignalModal/SignalModal'

const Root = observer(() => {
  const { uiStore, configStore } = useStores()
  const { showAlgorithms } = uiStore

  useEffect(() => {
    configStore.setTradeHandle('')
  }, [])

  return (
    <>
      <Header />
      <SignalModal />
      <div className='app-container'>
        <AreaTree />
        <div className='main-container'>
          <PortfolioPicker />
          <ActiveOrders />
          <Orders />
          <div className='right'>
            <Executor />
            <PopupMenu />
            {showAlgorithms && <AlgorithmMenu />}
          </div>
        </div>
      </div>
      {uiStore.settingsVisible && <Settings />}
    </>
  )
})

export default Root
