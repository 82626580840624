import React, { type FC } from 'react'
import './modal.scss'
import { useClickOutsideChild } from '../../hooks/useClickOutside'

// Describes a button in the bottom of the modal
interface Control {
  name: string,
  action: () => void
  disabled?: boolean
}

interface ModalProps {
  children: React.ReactNode
  show: boolean
  buttons: Control[]
  onClickOutside: (() => void) | undefined
}

/**
 * This is a Modal.
 *
 * A css variable, --container-available, which describes the max height of the child-container is available within children
 * @param children the childe to render
 * @param show whether the modal is visible
 * @param buttons action buttons to add
 * @param onClickOutside action to add if user clicks outside modal
 * @constructor
 */
export const Modal: FC<ModalProps> = ({ children, show, buttons, onClickOutside }) => {

  const { childRef, parentHandler } = useClickOutsideChild(() => {
    if (onClickOutside) onClickOutside()
  })

  if (!show) return null

  return <div className={'modal-background'} onClick={parentHandler}>
    <div className={'modal-elem'} ref={childRef}>
      <div className={'modal-container'}>
        {children}
      </div>
      <div className={'modal-controls'}>
        {buttons
          .map(({ name, action, disabled }) => {
              const active = disabled !== undefined ? !disabled : true // Default to enabled

              return <div
                className={`button ${active ? '' : 'disabled'}`}
                key={name}
                onClick={active ? action : undefined}
              >
                {name}
              </div>
            }
          )}
      </div>
    </div>
  </div>
}