import type React from 'react';
import { useEffect, useRef } from 'react'

// Register document ClickHandler and call handler when we have click outside the ref component
// This registers the event listener to the document and it will not be removed until this component is destroyed
export const useClickOutside = (handler: (e: MouseEvent) => void, deps: React.DependencyList = []) => {
  const ref = useRef<HTMLDivElement>(null)

  const clickFunction = (e: MouseEvent) => {
    // Only call the function if ref is defined and the click was outside
    if (ref.current && !ref.current.contains(e.target as HTMLElement)) {
      handler(e)
    }
  }

  useEffect(() => {
    document.addEventListener('click', clickFunction)

    return () => {
      document.removeEventListener('click', clickFunction)
    }
  }, [ref, ...deps])

  return ref
}

// Capture clicks in parent handler that is outside the childRef rectangle bounds
export const useClickOutsideChild = (handler: (e: React.MouseEvent<HTMLDivElement>) => void, deps: React.DependencyList = []) => {
  const childRef = useRef<HTMLDivElement>(null)

  const parentHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    // Only call the function if ref is defined and the click was outside
    if (childRef.current && !childRef.current.contains(e.target as HTMLElement)) {
      handler(e)
    }
  }

  return { childRef, parentHandler }
}