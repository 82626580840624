import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { Modal } from '../Modal/Modal'
import { useSubscribe } from '../../hooks/useSubscribe'
import { getSignals, updateSignal } from '../../actions/Signal'
import { type AcceptSignal, type Signal } from '../../classes/Signal'
import './signal-modal.scss'
import { Cell, HCell, Row } from '../Table/Table'
import { formatQty } from '../OrderBookTable/OrderBookTable'
import { useStores } from '../../use-stores'
import { productToShortName, shortPortfolioName } from '../../Util'
import { toast } from 'react-toastify'

function format(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const SignalModal = observer(() => {
  const { configStore, uiStore, userStore } = useStores()
  const { products, portfolios, handle } = configStore
  const { authority } = userStore
  const { showSignalModal, signalPopUpEnabled } = uiStore
  const [signals, setSignals] = useState<Signal[]>([])
  const noOfSignals = useRef(signals.length)
  const signal = signals.find((s) => s.accepted == null)
  const hasWrite = authority === 'WRITE'

  useSubscribe('/topic/signal', (signal: Signal) => {
    setSignals((prev) => {
      return [...prev.filter((s) => s.id !== signal.id), signal]
    })
  })

  useEffect(() => {
    getSignals().then((signals) => {
      setSignals(signals)
    })
  }, [])

  useEffect(() => {
    uiStore.setSignalAvailable(signals.filter((s) => s.accepted == null).length)
  }, [signals])

  useEffect(() => {
    const prevNoOfSignals = noOfSignals.current
    const newNoOfSignals = signals.length
    noOfSignals.current = newNoOfSignals

    if (!signalPopUpEnabled || newNoOfSignals <= prevNoOfSignals) {
      return
    }

    uiStore.setShowSignalModal(true)
  }, [signals])

  function acceptSignal(accept: boolean) {
    return async () => {
      if (signal == null) {
        toast.error('No signal to accept or reject, this should not happen')
        return
      }
      const acceptSignal: AcceptSignal = {
        id: signal.id,
        accept,
        trader: handle,
      }

      updateSignal(acceptSignal).catch((e) => {
        toast.error('Failed to update signal')
      })
    }
  }

  function closeModal() {
    uiStore.setShowSignalModal(false)
  }

  if (signal == null) {
    return <></>
  }

  const product = products.find((p) => p.contractId === signal.contractId)
  const portfolio = portfolios.find((p) => p.eic === signal.area && p.exchange === signal.exchange)

  const rows = {
    algorithm: signal.tradeHandle,
    exchange: signal.exchange.replace('_', ' '),
    area: portfolio != null ? shortPortfolioName(portfolio) : signal.area,
    product: product != null ? productToShortName(product) : signal.contractId,
    side: signal.buy ? 'Buy' : 'Sell',
    quantity: `${formatQty(signal.qty)} MW`,
    comment: signal.comment,
  }

  return (
    <Modal
      show={showSignalModal}
      buttons={[
        { name: 'Accept', action: acceptSignal(true), disabled: !hasWrite },
        { name: 'Reject', action: acceptSignal(false), disabled: !hasWrite },
        { name: 'Minimize', action: closeModal },
      ]}
      onClickOutside={closeModal}
    >
      <div className="signal-modal">
        <div className="table grid-table">
          <Row>
            <HCell>Field</HCell>
            <HCell>Value</HCell>
          </Row>
          {Object.entries(rows)
            .filter(([_, value]) => value != null)
            .map(([key, value]) => (
              <Row key={key}>
                <Cell>{format(key)}</Cell>
                <Cell>{value}</Cell>
              </Row>
            ))}
        </div>
      </div>
    </Modal>
  )
})
