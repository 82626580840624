import axiosInstance from '../axiosInstance'
import { type AcceptSignal, type Signal } from '../classes/Signal'

// Check the existing signals
export const getSignals = async (): Promise<Signal[]> =>
  await axiosInstance.get<Signal[]>(`/api/signals`).then((response) => response.data)

// Accept or reject the signal
export const updateSignal = async (acceptSignal: AcceptSignal): Promise<void> =>
  await axiosInstance.patch(`/api/signals`, acceptSignal)
